@import '~antd/dist/antd.css';

@font-face {
  font-family: 'CircularStd-Black';
  src: url('/static/fonts/CircularStd-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'CircularStd-BlackItalic';
  src: url('/static/fonts/CircularStd-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: url('/static/fonts/CircularStd-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-BoldItalic';
  src: url('/static/fonts/CircularStd-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Book';
  src: url('/static/fonts/CircularStd-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-BookItalic';
  src: url('/static/fonts/CircularStd-BookItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-Medium';
  src: url('/static/fonts/CircularStd-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'CircularStd-MediumItalic';
  src: url('/static/fonts/CircularStd-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('/static/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('/static/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('/static/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('/static/fonts/Roboto-Light.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  margin: 0;
  font-size: 16px;
}

h1 {
  font: 60px CircularStd-Bold;
}

h2 {
  font: 40px CircularStd-Book;
}

h3 {
  font: 30px CircularStd-Bold;
}

h4 {
  font: 24px CircularStd-Medium;
}

h5 {
  font: 24px CircularStd-Bold;
}

h6 {
  font: 22px CircularStd-Bold;
}

p, span, a {
  font-family: CircularStd-Book;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 767px) {
  .container {
    max-width: 100vw;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}


@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
